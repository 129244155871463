<template>
<v-container class="pa-5">
      <v-row no-gutters justify="center">
     <v-col
      sm="5"
     >
       <v-form  
              
                ref="form"
                v-model="valid"
                autocomplete="off"
                lazy-validation
                style="width: 100%; margin: auto;"
              >
            <v-card style="margin-top: 5%;  background: rgba(255,255,255, 0.8)" class="pa-2" flat>
              <p class="text-center">
              <img :src="$store.state.systemlogo" width="68px"/>
              </p>
              <div class="text-center text-h5 text-secondary">Membership Form</div>
               <v-card-text>
                   <v-text-field
                        v-model="referrerID"
                        label="Referral. Number"
                      @change="getUpline()"
                        outlined
                      ></v-text-field>
               </v-card-text>
              <v-card-text v-if="validID">
                <p>Enter your information below.</p>
                      <v-text-field
                        v-model="user.fullname"
                        :rules="nameRules"
                        label="Fullname *"
                        required
                        outlined
                      ></v-text-field>
                      <v-text-field
                        v-model="user.username"
                        :rules="nameRules"
                        label="Username *"
                        required
                        outlined
                      ></v-text-field>
                   
                      <v-text-field
                        v-model="user.password"
                        :rules="nameRules"
                        label="Password *"
                        type="password"
                        required
                        outlined
                      ></v-text-field>
                      <v-text-field
                        v-model="repassword"
                        :rules="nameRules"
                        label="Confirm Password *"
                        type="password"
                        required
                        outlined
                      ></v-text-field>
                       <v-text-field
                        v-model="user.contact_no"
                        :rules="nameRules"
                        label="Mobile No. *"
                        required
                        outlined
                      ></v-text-field>

                      <v-text-field
                        v-model="user.email"
                        label="Email"
                        outlined
                      ></v-text-field>
                    
              </v-card-text>
              <v-card-actions class="pt-1 pb-5" v-if="validID">
                  <v-btn
                  class="btn-grad-primary"
                        color="secondary"
                        block
                        rounded
                        large
                        :loading="loading"
                        @click="validate()"
                      >
                        register
                      </v-btn>
              </v-card-actions>
                <p class="text-center mt-5">
                        Already have an account? <v-btn text @click="$router.push('/login').catch(err => {})" color="warning">Login here</v-btn>
                    </p>
            </v-card>
          </v-form>
        
     </v-col>
   </v-row>
   <va-reg-complete :show="confirm" text="We will review your details and activate your account." @DialogEvent="cEvent"/>
</v-container>
</template>
<script>
// import md5 from 'md5'
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      valid: true,
      confirm: false,
      loading: false,
      username: '',
      user:{},
      nameRules: [
        v => !!v || 'Field is required'
      ],
      repassword: '',
      referrerID: null,
      validID: false,
      snackbar: false,
    }),
    created() {
       this.PING()
        this.setLoggedIn(false)
        console.log(this.$route.params.id)
        this.referrerID=this.$IsNum(this.$route.params.id)
        this.getUpline()
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      cEvent(){
          this.confirm = false
           this.$router.push('/')
      },
      validate () {

        if (this.$refs.form.validate()) {
            if(this.repassword != this.user.password) {
                alert("Pasword not match!")
                return
            }
            this.Save()
        }
      },
      getUpline(){
        if(this.referrerID.length == 0) {
          return
        }
         this.validID = false
          this.$http.post('user/detailspub', {id: parseInt(this.referrerID)}).then(response => {
                  console.log(response.data)
                if(!response.data.status) { 
                           this.VA_ALERT("error","Referrence number is not valid!")
                        //this.$router.push('/')
                } else {
                    if(response.data.account.role=="Player") {
                      this.VA_ALERT("error","Account no permission!")
                      return 
                    }
                    this.validID = true
                    this.user.area = response.data.account.area
                    if(response.data.account.role=="General Coordinator") {
                        this.user.coordinator = response.data.account.id
                    } else if (response.data.account.role=="Coordinator") {
                        this.user.coordinator = response.data.account.id//response.data.account.coordinator
                        //this.user.agent = response.data.account.id
                    } else if (response.data.account.role=="Agent") {
                        this.user.coordinator = response.data.account.coordinator
                        this.user.agent =response.data.account.id//response.data.account.agent
                        // this.user.sub_agent = response.data.account.id
                    } else if (response.data.account.role=="Player") {
                        //this.user.coordinator = response.data.account.coordinator
                        //this.user.agent = response.data.account.agent
                        // this.user.sub_agent = response.data.account.id
                    }
                }
        }).catch(e => {
        console.log(e)
        })
      },
      Save() {
         
        let method = 'account/register'
        this.user.role = "Player"
        this.user.username = this.user.username.trim()
       
        this.$http.post(method, this.user).then(response => {
             console.log(this.user)
             console.log(response.data)
            response.data.status? this.VA_ALERT('success', "Your account has been created!"): this.VA_ALERT('error', response.data.message)
            if(response.data.status) {
                setTimeout(()=>{this.confirm = true}, 3000)
            }   
        }).catch(e => {
        console.log(e)
        })
     },
    },
  }
</script>